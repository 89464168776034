@keyframes showCard{
    from {
        opacity: 0;
        transform: translate3d(0, 100px, 0);
    }
    to { 
        opacity: 1; 
        transform: translate3d(0, 0, 0); 
    }
}

.card{
    display: block;
    max-width: 363px;
    max-height: 560px;
    background-image: url('../../img/workers/mill.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;

    // Setup card types
    &.bg-1{ background-image: url('../../img/worker-bg-1.png'); }
    &.bg-2{ background-image: url('../../img/worker-bg-2.png'); }

    &.mill{ background-image: url('../../img/workers/mill.png'); }
    &.brewery{ background-image: url('../../img/workers/brewery.png'); }
    &.barracks{ background-image: url('../../img/workers/barracks.png'); }
    &.castle{ background-image: url('../../img/workers/castle.png'); }
    &.cottage{ background-image: url('../../img/workers/cottage.png'); }
    &.inn{ background-image: url('../../img/workers/inn.png'); }
    &.guardhouse{ background-image: url('../../img/workers/guardhouse.png'); }

    &.barracks-guardhouse{ background-image: url('../../img/workers/barracks-guardhouse.png'); }
    &.brewery-barracks{ background-image: url('../../img/workers/brewery-barracks.png'); }
    &.cottage-brewery{ background-image: url('../../img/workers/cottage-brewery.png'); }
    &.cottage-castle{ background-image: url('../../img/workers/cottage-castle.png'); }
    &.cottage-guardhouse{ background-image: url('../../img/workers/cottage-guardhouse.png'); }
    &.guardhouse-castle{ background-image: url('../../img/workers/guardhouse-castle.png'); }
    &.inn-barracks{ background-image: url('../../img/workers/inn-barracks.png'); }
    &.inn-castle{ background-image: url('../../img/workers/inn-castle.png'); }
    &.inn-cottage{ background-image: url('../../img/workers/inn-cottage.png'); }
    &.mill-barracks{ background-image: url('../../img/workers/mill-barracks.png'); }
    &.mill-brewery{ background-image: url('../../img/workers/mill-brewery.png'); }

    // Anims
    &.showAnim{
        animation: showCard .5s linear;
    }
}