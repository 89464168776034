.marker{
    display: block;
    // width: 214px;
    // height: 215px;
    width: 40px;
    height: 40px;
    background-image: url('../../img/worker_marker.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
}