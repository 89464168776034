.modal.castleRangeAction{
    text-align: center;

    .sign{
        display: flex;
        align-items: center;
        justify-content: center;
        color: darkgray;
        font-size: 1.2rem;
        margin-bottom: 20px;
    }
    .sign > div{
        margin: 0 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .sign > div:before{
        content: '';
        display: block;
        background-repeat: no-repeat;
        background-size: contain;
        width: 40px;
        height: 40px;
        margin-right: 10px;
    }

    .sign > .workers:before{
        background-image: url('../../img/worker_marker.png');
    }
    .sign > .points:before{
        background-image: url('../../img/coin.png');
    }

    .btn{
        display: table;
        color: cornsilk;
        background: darkcyan;
        padding: 10px 20px;
        font-size: 1.3rem;
        border-radius: 15px;
        margin: 10px auto;
    }
}