.gameEnd .chartWrap{
    max-width: 1200px;
    height: 500px;
    overflow: auto;
}

.gameEnd .winners > li{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    
    &:before{
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        margin-right: 10px;
    }

    &.winner:before{
        background-image: url('../../img/crown.png');
        background-repeat: no-repeat;
        background-size: contain;
    }

    .nickname{
        margin-right: 10px;
    }
}