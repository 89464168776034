.gameSettings label span{
    max-width: 400px;
    font-size: 1.3rem;
}

.gameSettings .block{
    margin-bottom: 50px;
}
.gameSettings .block p {
    margin-bottom: 10px;
}

.gameSettings .sign{
    color: cornsilk;
}

.gameSettings .players .sign{
    margin-top: 20px;
    font-style: italic;
}