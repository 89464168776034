.players > .list{
    display: flex;
    flex-wrap: wrap;
}

.players > .list > li{
    display: block;
    width: 80px;

    &.btn{
        width: 40px;
        height: 40px;
        margin: 10px 5px;
        background-repeat: no-repeat;
        background-size: contain;
        border-radius: 100%;

        &.addBtn{
            background-image: url('../../img/plus.png');
        }

        &.removeBtn{
            background-image: url('../../img/minus.png');
        }
    }
}

.player .avatar{
    position: relative;
    width: 60px;
    height: 60px;
    display: block;
    margin: auto;
    border-radius: 100%;
}

.player .avatar:before{
    content: '';
    display: block;
    width: 60%;
    height: 60%;
    position: absolute;
    top: 20%;
    left: 20%;
    z-index: 10;
    background-image: url('../../img/avatar.png');
    background-size: contain;
    background-position: 50%;
}

.player.bot .avatar:before{
    background-image: url('../../img/ai.png');
}

.player:nth-child(1) .avatar{
    background-color: $playerColor1;
}
.player:nth-child(2) .avatar{
    background-color: $playerColor2;
}
.player:nth-child(3) .avatar{
    background-color: $playerColor3;
}
.player:nth-child(4) .avatar{
    background-color: $playerColor4;
}

.player .nickname{
    margin-top: 10px;
    text-align: center;
}