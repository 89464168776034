.kingdoms{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.kingdom{
    width: 100%;
}

.kingdom > .title{
    font-size: 1.8rem;
    text-align: center;
    margin-bottom: 10px;

    > .nickname{
        font-weight: bold;
        white-space: nowrap;
    }
}

.playerInfo{
    font-size: 1.3rem;
    display: flex;
    align-items: center;
    justify-content: center;

    > .row{
        display: flex;
        align-items: center;
        margin-left: 20px;
        margin-bottom: 15px;

        &:first-child{
            margin-left: 0;
        }
    }

    > .row:before{
        content: '';
        display: block;
        width: 30px;
        height: 30px;
        background-repeat: no-repeat;
        background-size: contain;
        margin-right: 10px;
    }

    > .points:before{
        background-image: url('../../img/coin.png');
    }

    > .workers:before{
        background-image: url('../../img/worker_marker.png');
    }
}

.kingdom > .buildings{
    overflow-x: auto;
    overflow-y: hidden;
    max-width: 1600px;
    height: 680px;
    margin: 0 auto 50px;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: 0;

    @include media('>980px') {
        transition: .5s;
    }
}

.kingdom > .buildings > .slot{
    position: relative;
    padding-top: 80px;
    width: 200px;
    height: 100%;
}

.kingdom > .buildings > .slot > .workersCount{
    position: absolute;
    top: 40px;
    left: 50%;
    width: 50px;
    height: 50px;
    transform: translate3d(-50%, -25px, 0);
    user-select: none;

    background-color: cornflowerblue;
    border: 2px solid #3062bb;
    border-radius: 100%;
    
    color: #ffffff;
    font-weight: bold;
    font-size: 1.3rem;
    text-align: center;
    line-height: 48px;
}

.kingdom .building{
    height: 300px;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-position: center center;

    &.type_A{
        &.mill{ background-image: url('../../img/kingdom-1/mill.png'); }
        &.brewery{ background-image: url('../../img/kingdom-1/brewery.png'); }
        &.barracks{ background-image: url('../../img/kingdom-1/barracks.png'); }
        &.castle{ background-image: url('../../img/kingdom-1/castle.png'); }
        &.cottage{ background-image: url('../../img/kingdom-1/cottage.png'); }
        &.inn{ background-image: url('../../img/kingdom-1/inn.png'); }
        &.guardhouse{ background-image: url('../../img/kingdom-1/guardhouse.png'); }
        &.infirmary{ background-image: url('../../img/kingdom-1/infirmary.png'); }
    }
    &.type_B{
        &.mill{ background-image: url('../../img/kingdom-2/mill.png'); }
        &.brewery{ background-image: url('../../img/kingdom-2/brewery.png'); }
        &.barracks{ background-image: url('../../img/kingdom-2/barracks.png'); }
        &.castle{ background-image: url('../../img/kingdom-2/castle.png'); }
        &.cottage{ background-image: url('../../img/kingdom-2/cottage.png'); }
        &.inn{ background-image: url('../../img/kingdom-2/inn.png'); }
        &.guardhouse{ background-image: url('../../img/kingdom-2/guardhouse.png'); }
        &.infirmary{ background-image: url('../../img/kingdom-2/infirmary.png'); }
    }
}

.kingdom .workersCards{
    position: relative;
    width: 100%;
    height: 300px;
    overflow: hidden;

    > .card{
        position: absolute;
        width: 70%;
        height: 70%;
        left: 15%;
        top: 5px;

        @for $i from 1 through 12 {
            &:nth-child(#{$i}) {
                top: #{5 + $i * 7}px;
            }
        }
    }
}

.toggleCollapse{
    display: inline-block;
    margin: 10px 0;
    user-select: none;

    &:after{
        content: '';
        display: block;
    }
}

.kingdoms{
    &[data-players="2"] > .kingdom.collapsed{
        width: 46%;
    }

    &[data-players="3"] > .kingdom.collapsed{
        width: 30%;
    }

    &[data-players="4"] > .kingdom.collapsed{
        width: 18%;
    }

    @include media('<670px') {
        &[data-players="2"] > .kingdom.collapsed,
        &[data-players="3"] > .kingdom.collapsed,
        &[data-players="4"] > .kingdom.collapsed
        {
            width: 100%;
        }        
    }
}

.kingdom.collapsed{
    // width: 300px;
    min-width: 300px;
    display: inline-block;
    order: 1;
    margin-bottom: 50px;

    > .title{
        min-height: 60px;
    }

    > .buildings{
        max-width: inherit;
        height: 190px;
    }

    > .buildings > .slot{
        width: 100px;
        padding: 0;
    }

    > .buildings > .slot > .workersCount{
        top: 60px;
    }

    .workersCards{
        display: none;
    }

    .building{
        background-position: top center;
        height: 100%;
    }
}