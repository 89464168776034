html, body{
    font-family: Arial, Helvetica, sans-serif;
    font-size: 16px;
    height: 100%;
    color: #ffffff;
}

* {
    box-sizing: border-box;
}

main{
    min-height: 100%;
    padding: 70px 20px 20px;
    background: url('../img/main-bg.jpg');
    background-size: 100% auto;
    @include media('>980px') {
        cursor: url('../img/cursor.png'), auto;
    }
}

h1{
    font-size: 2rem;
    text-align: center;
    margin-bottom: 20px;
}

a{
    color: #ffbf00;
    @include media('>980px') {
        transition: color .5s;
        &:hover{
            color: #00c3ff;
        }
    }
}

.wrap{
    max-width: 1200px;
    margin: 0 auto;
}

.select{
    max-width: 400px;
    margin: 10px 0 20px;
}

.svgAtlas{
    display: none;
}

.playBtn,
.playAgainBtn{
    display: table;
    padding: 10px 20px;
    color: #ffffff;
    background: #ff7800;
    border-radius: 5px;
    user-select: none;
    transition: .5s;

    &:hover{
        background: rgba(#ff7800, .8);
    }
}

.chart{
    overflow-x: auto;
    overflow-y: hidden;
}