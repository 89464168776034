.gameHeader{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    z-index: 500;
    background: rgba(0, 75, 109, .9);
    box-shadow: 0px 10px 10px -6px rgba(0,0,0,1);
    text-align: center;
    color: #ffffff;
    font-size: 1.2rem;
    padding: 0 20px;

    display: flex;
    justify-content: center;
    align-items: center;
}

.gameHeader .turn{
    white-space: nowrap;
    margin-right: 10px;
}

.gameHeader .playerName{
    font-weight: bold;
}