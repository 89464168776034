.market{
    overflow: auto;
    max-width: 1460px;
    height: 380px;  
    margin: 0 auto 50px;

    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 10px;  
}

.market > .slot{
    position: relative;
    width: 200px;
    height: 100%;
}

.market .card{
    width: 100%;
    height: 300px;
    background-position: 50% 0;

    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 10px;
    position: relative;

    &:before{
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, .7);
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        opacity: 0;
        transition: opacity .5s;
    }

    &.haveWorkers:hover:before{
        opacity: 1;
    }

    &.store-slot:before{
        content: none;
    }
}

.market .card > .marker{
    margin: 5px;
    position: relative;
    z-index: 2;
}

.market .btn{
    display: table;
    text-align: center;
    color: cornsilk;
    background: cornflowerblue;
    padding: 10px 20px;
    border-radius: 15px;
    margin: 15px auto 0;
}