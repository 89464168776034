.modal{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .96);
    z-index: 999;

    display: flex;
    justify-content: center;
    align-items: center;
}

.modal > .modal-content > .title{
    color: #ffffff;
    text-align: center;
    font-size: 2rem;
    margin-bottom: 20px;

    @include media('<440px') {
        margin-bottom: 0;
    }
}

.modal > .modal-content > .cards{
    height: calc(100% - 50px);
    text-align: center;
}

.modal > .modal-content .card{
    display: inline-block;
    width: 300px;
    height: 400px;

    @include media('>980px') {
        transition: transform .5s;
        &:hover{
            transform: scale3d(1.2, 1.2, 1.2);
        }
    }

    @include media('<640px') {
        width: 200px;
        height: 300px;
        margin: 0 10px;
    }

    @include media('<440px') {
        width: 100px;
        height: 200px;
    }
}